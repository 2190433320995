import Choices from 'choices.js'

document.addEventListener  'turbolinks:load', () ->
  el = document.querySelectorAll 'select[multiple="multiple"]'
  return false if(el.length == 0)

  choices = new Choices 'select[multiple="multiple"]', {
    removeItemButton: true
    searchChoices: true
    searchEnabled: true
    loadingText: 'Loading...'
    noResultsText: 'No se encontraron resultados'
    noChoicesText: 'No se encontraron opciones'
    itemSelectText: 'Click para seleccionar'
    addItemText: (value) -> "Enter para agregar: <b>#{ value }</b>"
    maxItemText: (maxItemCount) -> "Solo #{ maxItemCount } pueden ser añadidos"
  }
